import React from 'react';
import { Col, Container, ResponsiveEmbed, Row } from 'react-bootstrap';
import { Disqus, Footer, Layout, Navbar, PageHeader, Poster, Sponsor } from '@components';
import { orderBy } from 'lodash';
import { videoFallback } from '@variables';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';

const SeniorProjectPage = ({ data }) => {
  const { project } = data.postgres;

  return <Layout className="senior-project-page">
    <Navbar year={project.year} />
    <PageHeader
      title={project?.title}
      subTextComponent={<>
        <p>{project?.year} - Team {project?.teamNumber}</p>
        <p>{project?.topic}</p>
      </>}
    />

    <Container style={{ marginBottom: `1rem`, marginTop: `3rem` }}>
      {project?.videoUrl && <Row className="mb-4 justify-content-center">
        <Col lg={8} style={{ marginBottom: `1rem` }}>
          <ResponsiveEmbed aspectRatio="16by9">
            <iframe
              title={project?.title}
              src={videoFallback ? project?.backupVideoUrl : project?.videoUrl}
              className="embed-responsive-item"
              allow="autoplay; fullscreen"
              allowFullScreen
            />
          </ResponsiveEmbed>
        </Col>
      </Row>}
      <Row className="mb-4 align-items-center">
        <Col lg={6}>
          <h2>Abstract</h2>
          <p>
            {project?.abstract}
          </p>
        </Col>
        <Col lg={6} style={{ marginBottom: `1rem` }}>
          <Poster poster={project?.posterFile} />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <h2>Members</h2>
          <Row className="mb-4">
            {orderBy(project?.members, `name`).map(member =>
              <Col key={member.name} md={2} xs={4} className="text-center">
                {
                  member?.imageFile ?
                    <GatsbyImage
                      image={getImage(member?.imageFile)}
                      alt={member.name}
                    /> :
                    <StaticImage layout="fullWidth" src="../assets/images/person-placeholder.png" alt={member.name} />
                }
                <p>{member.name}</p>
              </Col>)}
          </Row>
          <p><strong>Advisor: </strong>{project?.advisor}</p>
          {
            project?.website &&
              <p>
                <strong>Live Demo: </strong>
                <a href={project?.website}
                  target="_blank"
                  rel="noopener noreferrer">
                  {project?.website}
                </a>
              </p>
          }
        </Col>
      </Row>
      {project.judgingSessionId && <Row className="mb-4 ml-0">
        <p><a href={`/judging/${project.judgingSessionId}`}>Click here to view the Judging session</a></p>
      </Row>}
      <Disqus
        identifier={`senior-${project?.id}`}
        title={project?.title}
        pageLocation={`/seniors/${project?.id}`}
      />
    </Container>

    <Sponsor />
    <Footer />

  </Layout>;
};

export const query = graphql`
  query SeniorDesignProjectPageQuery($projectId: Int!) {
    postgres {
      project: seniorDesignProjectById(id: $projectId) {
        id
        abstract
        advisor
        backupVideoUrl
        judgingTime
        poster
        posterFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        teamNumber
        title
        topic
        videoUrl
        website
        year
        members: seniorDesignMembersByProjectIdList {
          image
          imageFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          name
        }
        judgingSessionId
      }
    }
  }
`;

export default SeniorProjectPage;
